import { ref } from '@vue/composition-api'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'

// import router from '../router/index.js';

export default function useDocuments() {
  const document = ref([])
  const loader = ref(false)
  const documentProcess = ref(false)
  const documentSuccess = ref(false)
  const documents = ref([])
  const errors = ref('')

  // Liste des documents
  const getDocuments = async () => {
    loader.value = true
    await axiosClient.get('/documents').then(response => {
      if (response.data.success === true) {
        loader.value = false
        documents.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une document
  const getDocument = async slug => {
    const response = await axiosClient.get(`/documents/${slug}`)
    document.value = response.data.data
  }
  // Ajouter une document
  const createDocument = async data => {
    errors.value = ''
    documentProcess.value = true
    await axiosClient.post('/documents', data)
      .then(response => {
        if (response.data.success === true) {
          documentSuccess.value = true
          documentProcess.value = false
          getDocuments()
        }
      })
      .catch(error => {
        documentProcess.value = false
        documentSuccess.value = false
        errors.value = error.response.data.errors
      })
  }

  // Modifier une document
  const updateDocument = async data => {
    errors.value = ''
    documentProcess.value = true
    await axiosClient.put(`/documents/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          documentSuccess.value = true
          documentProcess.value = false
          getDocuments()
        }
      })
      .catch(error => {
        documentProcess.value = false
        documentSuccess.value = false

        errors.value = error.response.data.errors
      })
  }

  return {
    errors,
    document,
    documents,
    getDocuments,
    documentProcess,
    createDocument,
    updateDocument,
    getDocument,
    loader,
    documentSuccess,
  }
}
